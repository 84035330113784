import React from 'react'

function useSupportEmail() {
  const supportEmail = 'care@provenskincare.com'

  return {
    supportEmail
  }
}

export const withSupportEmail = WrappedComponent => props => {
  const { supportEmail } = useSupportEmail()
  return <WrappedComponent {...props} supportEmail={supportEmail} />
}

export default useSupportEmail
